import { waitFor } from "./utils";

const env = document.body.getAttribute( "data-api" ) || "api";
let svc: string;
if ( env === "local" ) {
	if ( import.meta.env.DEV ) {
		svc = "https://localhost:51655";
	} else {
		const m = /analytics-(\w+)\.scorpion\.co/.exec( window.location.hostname );
		if ( m?.[1] ) {
			svc = `https://${m[1]}.scorpion.co/platform/analytics`;
		} else {
			svc = "https://sa.scorpion.co";
		}
	}
} else if ( env === "api" ) {
	svc = "https://sa.scorpion.co";
} else if ( env.endsWith( "-csx" ) ) {
	const env2 = env.replace( "-csx", "" );
	svc = `https://${env2}.scorpion.co/platform/analytics`;
} else {
	svc = `https://${env}.scorpion.co/platform/analytics`;
}

export interface Message {
	sa: string;
	page: string;
}

export interface VisitEvent extends Message {
	visitorId?: string;
	sessionId?: string;
	paid?: string;
	referrer?: string;
	keywords?: string;
	width: number;
	height: number;
	timeZone: number;
	phoneNumber?: string;
	adId?: number;
	adExtension?: number;
	campaignId?: number;
	keywordId?: number;
	location?: number;
	engagement?: string[];
	pooling?: string[];
}

export interface VisitResponse {
	visitorId: string;
	sessionId: string;
	hitId: string;
}

export interface ClickEvent extends Message {
	sessionId: string;
	hitId: string;
	selector: string;
	width: number;
	height: number;
	scrollTop: number;
	x: number;
	y: number;
	href?: string;
	post: boolean;
	search?: string;
	location: number;
}

export interface RestartEvent extends VisitEvent {
	restartId: string;
}

export interface RestartResponse extends VisitResponse {
	paid?: string;
	adId?: number;
	adExtension?: number;
	campaignId?: number;
}

export interface UpdatePhoneEvent extends Message {
	sessionId: string;
	hitId: string;
	phoneNumber: string;
	pooling?: string[];
}

export interface PlayEvent extends Message {
	videoPlayId?: string;
	sessionId: string;
	hitId: string;
	videoId: string;
	video: string;
	from: number;
	duration: number;
	autoStart: boolean;
	location: number;
}

export interface PlayResponse {
	videoPlayId: string;
	videoPlayingId: string;
}

export interface PlayingEvent extends Message {
	videoPlayId: string;
	videoPlayingId: string;
	to: number;
	duration: number;
	location: number;
}

export interface InitEvent extends Message {
	id: string;
}

export interface InitResponse {
	legacyId: number;
	sessionId: string;
	midnight: number;
}

export type QueuableEvent = ClickEvent | PlayEvent | PlayingEvent;

export function isClickEvent( evt: QueuableEvent ): evt is ClickEvent {
	return ( evt as ClickEvent ).width !== undefined;
}

export function isPlayEvent( evt: QueuableEvent ): evt is PlayEvent {
	return ( evt as PlayEvent ).video !== undefined;
}

export function isPlayingEvent( evt: QueuableEvent ): evt is PlayingEvent {
	return ( evt as PlayingEvent ).to !== undefined;
}

export async function logVisit( payload: VisitEvent ): Promise<VisitResponse | undefined> {
	const response = await sendTo<VisitResponse>( "/event/visit", payload );
	return response?.result;
}

export async function logClick( payload: ClickEvent ): Promise<string | undefined> {
	const response = await sendTo<string>( "/event/click", payload );
	return response?.result;
}

export async function logRestart( payload: RestartEvent ): Promise<RestartResponse | undefined> {
	const response = await sendTo<RestartResponse>( "/event/restart", payload );
	return response?.result;
}

export async function logUpdatePhone( payload: UpdatePhoneEvent ): Promise<string | undefined> {
	const response = await sendTo<string>( "/event/update-phone", payload );
	return response?.result;
}

interface VideoAnalyticsStatus {
	vvid: string;
	vpid: string;
}

export async function logPlay( payload: PlayEvent ): Promise<PlayResponse | undefined> {
	const response = await sendTo<PlayResponse>( "/event/play", payload );
	const result = response?.result;
	updateVideoElement( payload, result );
	return result;
}

/**
 * Find the matching video element in the DOM, and register the unique ids for the play event.
 * @param evt
 * @param result
 */
function updateVideoElement( evt: PlayEvent, result?: PlayResponse ) {
	if ( result?.videoPlayId && evt.videoId ) {
		const video = document.getElementById( evt.videoId );
		if ( video instanceof HTMLVideoElement ) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const sa = ( <any>video ).sa as VideoAnalyticsStatus | undefined;
			if ( sa ) {
				sa.vvid = result.videoPlayId;
				sa.vpid = result.videoPlayingId;
			}
		}
	}
}

export async function logPlaying( payload: PlayingEvent ): Promise<string | undefined> {
	const response = await sendTo<string>( "/event/playing", payload );
	return response?.result;
}


// The init may take a while -- we only one to do one at a time.
let _initSession: Promise<InitResponse | undefined> | undefined;

export function logInit( payload: InitEvent ): Promise<InitResponse | undefined> {
	if ( !_initSession ) {
		_initSession = new Promise<InitResponse | undefined>( resolve => initWithRetry( payload, resolve ) );
	}
	return _initSession;
}

// After two minutes, the init is considered expired.
const INIT_RETRY_EXPIRES = 1000 * 60 * 2;

/**
 * Initialize the session, retrying as needed.
 * @param payload
 * @param resolve
 * @returns
 */
async function initWithRetry( payload: InitEvent, resolve: ( result: InitResponse | undefined ) => void ) {
	const start = performance.now();

	// Repeat until we've reached the expiry time.
	while ( ( performance.now() - start ) < INIT_RETRY_EXPIRES ) {
		const response = await sendTo<InitResponse>( "/event/init", payload );
		if ( typeof response?.result?.legacyId === "number" ) {
			// If we have a legacy number, we're finished.
			resolve( response?.result );
			_initSession = undefined;
			return;
		}

		// Wait 5 seconds and try again.
		await waitFor( 5000 );
	}

	console.error( "Unable to establish legacy id." );
	resolve( undefined );
}

interface APIResponse<T> {
	result?: T;
	status?: {
		status: number;
		message: string;
	}
}

async function sendTo<T>( path: string, payload: Message ): Promise<APIResponse<T>> {
	const url = `${svc}${path}`;
	const response = await fetch(
		url, {
			method: "POST",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			},
			body: JSON.stringify( payload ),
			keepalive: true,
		}
	);
	const json = await response.json();
	return json as APIResponse<T>;
}
