import {
	ClickEvent,
	InitEvent,
	PlayEvent,
	PlayingEvent,
	QueuableEvent,
	RestartEvent,
	UpdatePhoneEvent,
	VisitEvent,
	isClickEvent,
	isPlayEvent,
	isPlayingEvent,
	logClick,
	logInit,
	logPlay,
	logPlaying,
	logRestart,
	logUpdatePhone,
	logVisit
} from "./api";
import { cookies } from "./cookies";
import {
	getClicked,
	getEngagement,
	getPooling,
	getSPPCHash,
	primaryPhone,
	setSPPCHash,
	timeZoneHours
} from "./utils";

declare global {
	interface Window {
		Process?: {
			Phones?: () => string | undefined;
			Delayed?: () => void;
		},
		_sa_videoStart?: ( videoId: string, videoPlayId: string | undefined, video: string, from: number, duration: number, autoStart: boolean ) => void;
		_sa_videoPlay?: ( videoPlayId: string, videoPlayingId: string, to: number, duration: number ) => void;
		_sa_getHitId: () => string | undefined;
	}
}

// A CMS website will have an SA code in the <html> node.
const data_sa = document.documentElement.getAttribute( "data-sa" );
const sa = data_sa ? decodeURIComponent( data_sa ) : "";

// These sites will not get the hashed PPC urls.
const enableHash = !document.documentElement.getAttribute( "data-sd" ) && !window.location.hostname.endsWith( "scorpion.co" );

const LEGACY_SESSIONID = "_sa";
const SESSIONID = "sa_";
const VISITORID = "vid_";
const SA_INFO = "sa_info";
const ONE_DAY = 24 * 60 * 60 * 1000;

export class SessionState {
	// Singleton for the session state.
	static current: SessionState = new SessionState();

	#ready?: () => void;
	ready: Promise<void>;

	private constructor() {
		this.ready = new Promise( resolve => this.#ready = resolve );

		const restart = this.#checkRestart();
		if ( !restart ) {
			this.#visit( true );
		}

		this._click = this._click.bind( this );
		this._play = this._play.bind( this );
		this._playing = this._playing.bind( this );

		// Add these oddly-named public methods that allow other video scripts to use this logging system.
		window._sa_videoStart = this._play;
		window._sa_videoPlay = this._playing;
		window._sa_getHitId = () => this.hidId;

		// Bind the click event 'onCapture' to make it a priority for the logging.
		document.addEventListener( "click", this._click, true );
	}

	get visitorId(): string | undefined {
		return String( cookies.get( VISITORID ) || "" ) || localStorage.getItem( VISITORID ) || undefined;
	}
	set visitorId( value: string ) {
		// Save visitorId in localStorage AND a cookie.
		localStorage.setItem( VISITORID, value );
		const expires = new Date();
		expires.setFullYear( expires.getFullYear() + 1 );
		cookies.set( VISITORID, value, expires );
	}

	get sessionId(): string | undefined {
		// We won't let a sessionId go longer than 24 hours.
		const info = Number( localStorage.getItem( SA_INFO ) );
		if ( !info ) {
			return undefined;
		}
		const expires = new Date().getTime() - ONE_DAY;
		if ( info < expires ) {
			return undefined;
		}

		const sessionId = cookies.get( SESSIONID );
		return sessionId ? String( sessionId ) : undefined;
	}
	set sessionId( value: string ) {
		cookies.set( SESSIONID, value );
		localStorage.setItem( SA_INFO, String( new Date().getTime() ) );
	}

	get legacyId(): number {
		return +( cookies.get( LEGACY_SESSIONID ) || 0 );
	}
	set legacyId( value: number ) {
		cookies.set( LEGACY_SESSIONID, value );

		// Trigger an event to note that the legacy sessionId value has been set.
		try {
			const evt = new CustomEvent( "analytics.sessionId", {
				bubbles: true,
				cancelable: true,
				detail: { sessionId: value }
			} );
			document.documentElement.dispatchEvent( evt );
		} catch( ex ) {/** */}
	}

	#hitId?: string;
	get hidId(): string | undefined {
		return this.#hitId;
	}
	set hitId( hitId: string ) {
		// The current hitId is only saved in memory, not persisted in any sort of storage.
		this.#hitId = hitId;
	}

	/**
	 * Log a click event.
	 * @param e
	 */
	_click( e: MouseEvent ) {
		// Build the click event info.
		const clicked = getClicked( e );
		const width = ( window.innerWidth || document.documentElement.offsetWidth || document.body.offsetWidth || 0 );
		const height = ( window.innerHeight || document.documentElement.offsetHeight || document.body.offsetHeight || 0 );
		const scrollTop = Math.max( document.documentElement.scrollTop, document.body.scrollTop );
		const evt: ClickEvent = {
			sessionId: this.sessionId || "",
			hitId: this.hidId || "",
			sa,
			page: window.location.href,
			selector: clicked.selector,
			width,
			height,
			scrollTop,
			x: e.pageX || e.clientX || 0,
			y: e.pageY || e.clientY || 0,
			href: clicked.href,
			post: clicked.post,
			search: clicked.search,
			location: Number( cookies.get( "L" ) ) || 0,
		};

		if ( evt.sessionId ) {
			// If we have a sessionId we can log it immediately.
			logClick( evt );
			return;
		} else {
			this.#queueEvent( evt );
		}
	}

	/**
	 * Log a new video play.
	 * @param videoId
	 * @param videoPlayId
	 * @param video
	 * @param from
	 * @param duration
	 * @param autoStart
	 * @returns
	 */
	_play( videoId: string, videoPlayId: string | undefined, video: string, from: number, duration: number, autoStart: boolean ): void {
		const evt: PlayEvent = {
			videoPlayId: videoPlayId || undefined,
			sessionId: this.sessionId || "",
			hitId: this.hidId || "",
			sa,
			page: window.location.href,
			videoId,
			video,
			from,
			duration,
			autoStart: !!autoStart,
			location: Number( cookies.get( "L" ) ) || 0,
		};

		if ( evt.sessionId ) {
			logPlay( evt );
		} else {
			this.#queueEvent( evt );
		}
	}

	/**
	 * Log that a video is still playing.
	 * @param videoPlayId
	 * @param videoPlayingId
	 * @param to
	 * @param duration
	 */
	_playing( videoPlayId: string, videoPlayingId: string, to: number, duration: number ): void {
		const evt: PlayingEvent = {
			videoPlayId,
			videoPlayingId,
			sa,
			page: window.location.href,
			to,
			duration,
			location: Number( cookies.get( "L" ) ) || 0,
		};
		logPlaying( evt );
	}

	#loading: boolean = false;
	#queue: QueuableEvent[] = [];

	/**
	 * If we don't have a sessionId, we'll need to queue the event until the visit is logged.
	 * @param evt
	 */
	#queueEvent( evt: QueuableEvent ) {
		this.#queue.push( evt );

		// If we're not already in the middle of logging the visit, trigger a new visit.
		if ( !this.#loading ) {
			this.#visit();
		}
	}

	/**
	 * Check for an SPPC hash in the url that indicates this link was shared from another user.
	 */
	#checkRestart(): boolean {
		if ( !enableHash ) {
			// SPPC hash disabled for this site.
			return false;
		}
		if ( cookies.get( "SPPC" ) ) {
			// If we already know we've got an SPPC session, we'll run the standard #visit().
			return false;
		}

		const sessionId = this.sessionId;
		const hash = getSPPCHash();
		if ( hash && hash !== sessionId ) {
			// If we have a hash without a matching sessionId, restart the SPPC session.
			this.#restart( hash );
			return true;
		}

		return false;
	}

	/**
	 * Take a previous sessionId, look up any sppc data, and restart the current session using that context.
	 * @param restartId
	 * @returns
	 */
	async #restart( restartId: string ) {
		// Try and restart the previous session.
		const evt1: RestartEvent = {
			...getVisitEvent( this.visitorId, this.sessionId ),
			restartId,
		};
		const restart = await logRestart( evt1 );

		// If we couldn't do it, log a standard visit.
		if ( !restart?.sessionId ) {
			await this.#visit();
			return;
		}

		// Set the ids.
		this.visitorId = restart.visitorId;
		this.sessionId = restart.sessionId;
		this.hitId = restart.hitId;

		if ( restart.paid ) {
			cookies.set( "SPPC", restart.paid, 30 );
			cookies.set( "PPCAD", restart.adId || null, 30 );
			cookies.set( "PPCEX", restart.adExtension || null, 30 );
			cookies.set( "PPCCMP", restart.campaignId || null, 30 );

			// Have the CMS reprocess the current page, so as to load any number pooling map.
			try { await fetch( window.location.href ); }
			catch( ex ) { /**/ }

			// If we have access to the CMS 'Process' function, run any delayed render.
			try { window.Process?.Delayed?.(); }
			catch( ex ) { /**/ }

			// If the displayed phone number changed, update it in the analytics logs.
			const phoneNumber = primaryPhone();
			if ( phoneNumber && phoneNumber !== evt1.phoneNumber ) {
				const evt2: UpdatePhoneEvent = {
					sessionId: restart.sessionId,
					hitId: restart.hitId,
					sa,
					page: window.location.href,
					phoneNumber,
					pooling: getPooling(),
				};
				await logUpdatePhone( evt2 );
			}
		}

		// If we have a PPC session make sure the address hash matches.
		const paid = cookies.get( "SPPC" );
		if ( paid && restart.sessionId && restart.sessionId !== getSPPCHash() ) {
			setSPPCHash( restart.sessionId );
		}
	}

	/**
	 * Log the visit on page load or after a session expires.
	 */
	async #visit( first: boolean = false ) : Promise<void> {
		// Build the payload.
		const visitorId = this.visitorId;
		const sessionId = this.sessionId;
		const evt1 = getVisitEvent( visitorId, sessionId );

		if ( first && enableHash && evt1.paid && sessionId ) {
			// If we already know we've got a PPC session, and we already have a sessionId,
			// update the address bar with the SPPC hash.
			setSPPCHash( sessionId );
		}

		// Log with the service.
		this.#loading = true;
		const visit = await logVisit( evt1 );
		this.#loading = false;

		if ( !visit?.sessionId ) {
			this.#ready?.();
			this.#ready = undefined;
			return;
		}

		// If the sessionId changed, clear the legacyId.
		if ( visit.sessionId !== sessionId ) {
			this.legacyId = 0;
		}

		// Set the ids.
		this.visitorId = visit.visitorId;
		this.sessionId = visit.sessionId;
		this.hitId = visit.hitId;

		if ( first && enableHash && evt1.paid && visit.sessionId && visit.sessionId !== getSPPCHash() ) {
			// If we have a PPC session make sure the address hash matches.
			setSPPCHash( visit.sessionId );
		}

		if ( first ) {
			// If we have access to the CMS 'Process' function, run any delayed render.
			try { window.Process?.Delayed?.(); }
			catch( ex ) { /**/ }
		}

		// We'll need to initialize the session if we don't have a legacy int-based sessionId.
		if ( !this.legacyId ) {
			const evt2: InitEvent = {
				id: this.sessionId,
				sa,
				page: window.location.href,
			};
			const init = await logInit( evt2 );
			if ( !init?.legacyId || init.sessionId !== visit.sessionId ) {
				return;
			}
			this.legacyId = init.legacyId;
		}

		// Handle anything in the queue.
		await this.#handleQueue();

		// We're now ready.
		this.#ready?.();
		this.#ready = undefined;
	}

	/**
	 * Process events that were waiting for the visit to be logged.
	 * @returns
	 */
	async #handleQueue() {
		const sessionId = this.sessionId;
		const hitId = this.hidId;
		if ( !sessionId || !hitId ) {
			return;
		}

		// Copy the queue and empty it out.
		const queue = [...this.#queue];
		if ( !queue.length ) {
			return;
		}
		this.#queue.length = 0;

		for ( const item of queue ) {
			if ( isClickEvent( item ) ) {
				item.sessionId = sessionId;
				item.hitId = hitId;
				await logClick( item );
			} else if ( isPlayEvent ( item ) ) {
				item.sessionId = sessionId;
				item.hitId = hitId;
				await logPlay( item );
			} else if ( isPlayingEvent ( item ) ) {
				await logPlaying( item );
			}
		}
	}
}


/**
 * Get the visit event payload.
 * @param visitorId
 * @param sessionId
 * @returns
 */
export function getVisitEvent( visitorId?: string, sessionId?: string ): VisitEvent {
	// Get any querystring parameters out of the URL.
	const url = new URL( window.location.href );
	const newParams = new Map<string, string>();
	const params = [...url.searchParams];
	for ( const [k, v] of params ) {
		const lower = k?.toUpperCase();
		if ( lower && v ) {
			newParams.set( lower, v );
		}
	}

	// If the value cannot be found in the cookie, check the querystring.
	const paid = cookies.get( "SPPC" ) as string || newParams.get( "SPPC" ) || undefined;
	const referrer = cookies.get( "SEOR" ) as string || document.referrer;
	const keywords = cookies.get( "SEOK" ) as string || newParams.get( "KEYWORD" ) || newParams.get( "KEYWORDS" ) || undefined;
	const width = ( window.innerWidth || document.documentElement.offsetWidth || document.body.offsetWidth || 0 );
	const height = ( window.innerHeight || document.documentElement.offsetHeight || document.body.offsetHeight || 0 );
	const adId = Number( cookies.get( "PPCAD" ) ) || Number( newParams.get( "SPPCADID" ) ) || undefined;
	const adExtension = Number( cookies.get( "PPCEX" ) ) || Number( newParams.get( "SPPCADEXID" ) ) || undefined;
	const campaignId = Number( cookies.get( "PPCCMP" ) ) || Number( newParams.get( "SPPCCAMPAIGNID" ) ) || undefined;

	return {
		visitorId,
		sessionId,
		sa,
		page: window.location.href,
		paid,
		referrer,
		keywords,
		width,
		height,
		timeZone: timeZoneHours(),
		phoneNumber: primaryPhone(),
		adId,
		adExtension,
		campaignId,
		location: Number( cookies.get( "L" ) ) || undefined,
		engagement: getEngagement(),
		pooling: getPooling(),
	};
}
