
/**
 * Modified encodeURIComponent that replaces quotes and paratheneses.
 * @param data
 */
export function encode( data: string ): string {
	if ( !data ) {
		return "";
	}
	return encodeURIComponent( String( data ) ).replace( /['"()]/g, function ( m ) {
		switch ( m ) {
			case "'":
				return "%27";
			case "\"":
				return "%22";
			case "(":
				return "%28";
			case ")":
				return "%29";
			default:
				return m;
		}
	} );
}

/**
 * Modified decodeURIComponent that turns + symbols into spaces.
 * @param data
 */
export function decode( data?: string ) {
	if ( !data ) {
		return "";
	} else {
		return decodeURIComponent( String( data ).replace( /\+/g, "%20" ) );
	}
}

/**
 * Wait for a specified number of milliseconds
 * @param ms
 * @returns
 */
export function waitFor( ms: number ) {
	return new Promise( ( resolve ) => {
		setTimeout( resolve, ms );
	} );
}

export interface ClickInfo {
	selector: string;
	href?: string;
	post: boolean;
	search?: string;
}

/**
 * What did we click on?
 * @param e
 * @returns
 */
export function getClicked( e: MouseEvent ): ClickInfo {
	const target = e.target as Element;
	const link = target?.closest( "a" );
	const href = link?.getAttribute( "href" ) || undefined;
	const button = target?.closest( "button" );
	const form = button?.closest( "form" );
	const action = form?.getAttribute( "action" );
	const post = form?.getAttribute( "method" ) === "post" || false;

	// What did we click on?
	const el = link || button || target;
	const search = action && form?.getAttribute( "data-search" ) && firstValue( form );

	// Get the selector for this item.
	const selectors = getSelectors( el );

	// Return the collection of data.
	return {
		selector: selectors.join( " " ),
		href,
		post,
		search: search || undefined
	};
}


/**
 * Get the first text value of a form.
 * @param form
 * @returns
 */
function firstValue( form?: HTMLFormElement ): string | undefined {
	const inputs = form?.getElementsByTagName( "input" );
	if ( !inputs ) {
		return undefined;
	}
	for ( const input of inputs ) {
		if ( input?.getAttribute( "type" ) === "text" && isElementVisible( input ) ) {
			return input.value;
		}
	}
	return undefined;
}


/**
 * Is an element being rendered on the page?
 * @param el
 * @returns
 */
function isElementVisible( el: HTMLElement ): boolean {
	const visible = el && ( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
	return !!visible;
}


/**
 * Build a selector query for this element.
 * @param el
 * @returns
 */
function getSelectors( el: Element ) {
	const parents = getParents( el );
	const selectors = [];

	// Add each of the items to the selection path.
	for ( const item of parents ) {
		let selector: string;
		if ( item.id ) {
			// An id is an exact match.
			selectors.unshift( `#${ item.id}` );
			// No need to go further.
			return selectors;
		} else if ( item.cls ) {
			selector = `${item.name }.${  item.cls}`;
		} else if ( item.name ) {
			selector = item.name;
		} else {
			continue;
		}

		// Check for an ambiguous selector.
		const elements = item.el.querySelectorAll( selector );
		if ( elements.length > 1 ) {
			const index = Array.prototype.indexOf.call( elements, item.el );
			if ( index !== -1 ) {
				// Add the index.
				selector += `:nth-child(${   index + 1  })`;
			}
		}

		// Add this selector to the collection.
		selectors.unshift( selector );
	}

	return selectors;
}

interface DOMInfo {
	el: Element;
	id?: string;
	name?: string;
	cls?: string;
}

/**
 * Get a collection of parent elements of the current element.
 * @param start
 * @returns
 */
function getParents( start: Element ): DOMInfo[] {
	const array = [];
	let el = start;
	while ( el && el.parentNode && el !== document.body && el !== document.documentElement ) {
		const id = el.getAttribute( "id" );
		if ( id ) {
			// The ID is the most significant selector.  If we found one, we're done.
			array.push( { el, id } );
			return array;
		}

		// If we have a CSS class or an LI, add it to the stack.
		const cls = cssClasses( el );
		const name = ( el.nodeName || "" ).toLowerCase();
		if ( cls || name === "li" || !array.length ) {
			array.push( { el, name, cls } );
		}
		el = el.parentNode as Element;
	}
	return array;
}

/**
 * Get the CSS classes of an element as a selector.
 * @param el
 * @returns
 */
function cssClasses( el: Element ): string {
	const cls = ( el.getAttribute( "class" ) || "" ).trim();
	if ( cls ) {
		return cls.replace( /(\s+)|([^\w-])/g, function ( _, m1, m2 ) {
			if ( m1 ) {
				return ".";
			} else if ( m2 ) {
				return `\\${m2}`;
			} else {
				return "";
			}
		} );
	} else {
		return "";
	}
}

/**
 * Current time zone measured as hours off of GMT.
 * @returns
 */
export function timeZoneHours(): number {
	const d = new Date();
	const jan = new Date( d.getFullYear(), 0, 1 );
	const jul = new Date( d.getFullYear(), 6, 1 );
	const tz = Math.max( jan.getTimezoneOffset(), jul.getTimezoneOffset() ) / 60;
	return tz;
}

/**
 * Return the first phone number on the page by looking for a "tel:" href.
 * @returns
 */
export function primaryPhone(): string | undefined {
	const links = document.getElementsByTagName( "a" );
	for ( const link of links ) {
		const href = link.getAttribute( "href" );
		const m = href && /^tel:(.+)$/.exec( href );
		if ( m ) {
			const phone = m[1].replace( /\D+/g, "" );
			if ( phone && phone.length >= 10 ) {
				return phone;
			}
		}
	}
	return undefined;
}

/**
 * Return a set of tracking ids that can be used for engagement.
 * @returns
 */
export function getEngagement(): string[] | undefined {
	const elements = document.getElementsByClassName( "ui-track-version" );
	const ids = new Set<string>();
	for ( let i = 0; i < elements.length; i++ ) {
		const id = elements[i].getAttribute( "id" );
		if ( id ) {
			ids.add( id );
		}
	}
	return ids.size ? [...ids] : undefined;
}

/**
 * If we have a access to the CMS 'Process' function, get the number pooling info
 * @returns
 */
export function getPooling(): string[] | undefined {
	let numbers: string | undefined;
	try {
		numbers = window.Process?.Phones?.();
	} catch ( ex ) { /**/ }
	return numbers?.split( "|" );
}

/**
	 * These are common PPC parameters -- we'll remove these when writing the hash url.
	 */
const ignoreParam = new Set( [
	"_cldee", "_gl", "_hsenc", "adct", "adid", "adposition", "adsubid", "bidmatchtype",
	"cam", "campaignid", "cmp", "creative", "date_created", "device", "devicemodel",
	"dicbo", "dynamic_proxy", "epik", "esid", "external_browser_redirect", "fbclid",
	"fccdbefkve", "fccdbeve", "furl", "g", "gad", "gclid", "hsa_acc", "hsa_ad",
	"hsa_cam", "hsa_grp", "hsa_kw", "hsa_mt", "hsa_net", "hsa_src", "hsa_tgt", "hsa_ver",
	"interestloc", "keyword", "keywordid", "kw", "li_fat_id", "loc_interest_ms",
	"loc_physical_ms", "matchtype", "mc_cid", "mc_eid", "ms", "msclkid", "nb",
	"network", "networktype", "nx", "oborigurl", "physicalloc", "pp", "primary_serv",
	"psafe_param", "pub_cr_id", "querystr", "random", "refpageviewid", "rl_key",
	"rl_site", "rl_siteid", "rl_sitelink", "se_action", "sppc", "sppcadexid",
	"sppcadid", "sppccampaignid", "sppckeywordid", "ssp_iabi", "targetid", "tblci",
	"tc", "testmode", "twclid", "useyb", "usps_mid", "usps_sn", "utm_brand",
	"utm_campaign", "utm_content", "utm_medium", "utm_scrub", "utm_source", "utm_term",
	"vs_key", "wbraid",
] );

/**
 * Replace the current url with a hash for the sessionId.  If this url is shared with
 * another user, it will "restart" the earlier sessions PPC data, so that attribution
 * goes to both.
 * @param sessionId
 */
export function setSPPCHash( sessionId: string ) : void {
	// If the current url has querystring parameters, we'll want to remove SPPC ones.
	let hashUrl;
	if ( window.location.search ) {
		const url = new URL( window.location.href );
		const newParams = new URLSearchParams();
		const params = [...url.searchParams];
		for ( const [k, v] of params ) {
			const lower = k?.toLowerCase();
			if ( !lower || !ignoreParam.has( lower ) ) {
				newParams.set( k, v );
			}
		}

		// We'll replace the current querystring.
		const search = newParams.toString();
		if ( search ) {
			hashUrl = `${window.location.pathname}?${search}#~${sessionId}`;
		} else {
			hashUrl = `${window.location.pathname}#~${sessionId}`;
		}
	} else {
		// Without querystring to worry about, all we need to do is replace the hash.
		hashUrl = `#~${sessionId}`;
	}

	window.history.replaceState( undefined, "", hashUrl );

}

/**
 * Check for an SPPC sessionId in the hash.
 * @returns
 */
export function getSPPCHash() : string | undefined {
	const hash = window.location.hash;
	if ( hash?.length === 38 && hash[1] === "~" ) {
		return hash.substring( 2 );
	} else {
		return undefined;
	}
}

/**
 * Get a unix timestamp representation of midnight tonight in the browser's current time zone.
 * @returns
 */
export function midnightTonight(): number {
	// Format the date MM/dd/yyyy.
	const now = new Date().toLocaleString(
		"en-US", {
			year: "numeric",
			month: "numeric",
			day: "numeric"
		} );

	// Get the parts of the date.
	const parts = now.split( "/" );
	const MM = Number( parts[0] ) - 1;
	const dd = Number( parts[1] );
	const yyyy = Number( parts[2] );

	// Construct a new date as of midnight of the previous day.
	const midnight = new Date( yyyy, MM, dd );

	// Advance to tonight's midnight.
	midnight.setDate( midnight.getDate() + 1 );
	return midnight.getTime();
}
