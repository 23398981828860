import { decode, encode } from "./utils";

export type CookieValue = string | number | boolean | Date | null;

export const cookies = {
	/**
	 * Return the value of a cookie.
	 *
	 * @param key
	 * @returns {any}
	 */
	get: ( key: string ): CookieValue => {
		const match = document.cookie.match( `(^|;)\\s*${key}\\s*=\\s*([^;]+)` );
		const value = match?.pop();
		if ( !value ) {
			return null;
		}
		switch ( value[0] ) {
			case "#":
				return +value.substring( 1 );
			case "!":
				return value === "!!";
			case ":":
				return new Date( +value.substring( 1 ) );
			default:
				return decode( value );
		}
	},

	/**
	 * Get or Set a cookie value.
	 *
	 * @param key
	 * @param value
	 * @param persist
	 */
	set: (
		key: string,
		value: CookieValue,
		persist: boolean | number | Date = false
	) => {
		if ( value === null || typeof value === "undefined" ) {
			// A null value means delete the cookie.
			cookies.delete( key );
			return;
		}

		// Encode the cookie value.
		let encoded;
		if ( typeof value === "number" ) {
			encoded = `#${value}`;
		} else if ( typeof value === "boolean" ) {
			encoded = value ? "!!" : "!";
		} else if ( !value ) {
			encoded = "";
		} else if ( value instanceof Date ) {
			encoded = `:${value.getTime()}`;
		} else if ( typeof value === "string" ) {
			encoded = encode( value );
		} else {
			encoded = String( value );
		}

		// Get any optional expiration.
		let date;
		if ( !persist ) {
			date = null;
		} else if ( persist instanceof Date ) {
			// Already a date.
		} else {
			date = new Date();
			if ( typeof persist === "number" ) {
				date.setDate( date.getDate() + persist );
			} else {
				date.setDate( date.getDate() + 30 );
			}
		}

		// Set the cookie in the document.
		const expires = date ? `; expires = ${date.toUTCString()}` : "";
		document.cookie = `${key}=${encoded}; path=/${expires}`;
	},

	/**
	 * Delete a cookie by name.
	 *
	 * @param key
	 */
	delete: ( key: string ) => {
		// Clear the cookie.
		const expires = new Date( 0 ).toUTCString();
		document.cookie = `${key}=; path=/; expires=${expires}`;
	},
};
